import React from 'react';
import {
  Box, Card, Dialog, DialogContent, Divider, Typography, Grid, TextField, InputAdornment, Chip
} from '@mui/material';
import { usePersonalReport } from './usePersonalReport';
import { btnS, headerBtn } from '../../../../Invoices/style';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconStyle } from '../../../../Logistics/OrdersDashboard/styles';
import FormGroup from '../../../../../form/components/FormGroup/FormGroup';
import { Controller } from 'react-hook-form';
import DatePicker from '../../../../../form/components/DatePicker';
import { setDateValue, validateDatePickerValue } from '../../../../../utils/setDateValue';
import { WhiteInput } from '../../../../Finance/InvoiceManagement/styles';
import Button from '../../../../../components/Button/Button';
import { GreenButton, TextWithIcon, YellowButton } from '../../../../Logistics/style';
import SendIcon from '@mui/icons-material/Send';
import Select from '../../../../../form/components/Select';
import { BONUS, COMPENSATION_STATUSES, TRANSFER_DETAILS } from '../../constants';
import { Plus, Rename } from 'mdi-material-ui';
import Autocomplete from '../../../../../form/components/Autocomplete';
import Upload from '../../../../../components/Upload';
import FieldError from '../../../../../form/components/FieldError';
import { CommentsStyle, RowStyle } from '../../styles';
import { defaultValues } from './schema';
import { StyledDelete } from '../../../../../components/EditableTable/styles';
import { formatNumber, numberFormatValidate } from '../../../../../utils/number';
import EuroIcon from '@mui/icons-material/Euro';
import PersonIcon from '@mui/icons-material/Person';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import SaveIcon from '@mui/icons-material/Save';
import { DialogMaxHeight } from '../../../../../assets/styles';

const PersonalReportForm = ({
  open, onClose, id, user
}) => {
  const {
    update,
    onSubmit,
    currencies,
    handleUploadFile,
    handleResetFile,
    handleDownloadFile,
    handleCloseOnSubmit,
    totalAmount,
    totalCompensationsAmount,
    hasComment,
    submitted,
    declined,
    opened,
    returned,
    draft,
    bankFileName,
    handleUploadBankFile,
    personalReportData,
    form: {
      watch,
      handleSubmit,
      control,
      setValue,
      getValues,
      formState: { errors }
    },
    rowFields,
    rowAppend,
    rowRemove,
    isLoadingStore,
    isLoadingUpdate,
    isLoadingAccept
  } = usePersonalReport(onClose, id);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleCloseOnSubmit}
      sx={DialogMaxHeight}
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Card sx={{ padding: 4, overflowY: 'scroll', height: '100%' }}>
            <Box sx={{ ...headerBtn, display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={TextWithIcon}>
                <Box>
                  <Typography variant="h5" noWrap>
                    {update ? 'Edit' : 'Create'}
                    {' '}
                    Personal Report
                  </Typography>

                  <Typography variant="subtitle2" noWrap>
                    All fields marked with * are required
                  </Typography>
                  <Typography sx={{ fontSize: '20px', color: 'red' }} variant="subtitle2" noWrap>
                    Attention! Do not enter bonus or penalty values in this entry.
                  </Typography>
                </Box>

                {update && (submitted || declined) && (
                  <Button
                    sx={{ ...(GreenButton), marginX: '20px' }}
                    title="Accept"
                    type="submit"
                    disabled={!watch('bank_file')?.name || isLoadingAccept}
                    startIcon={<DoneOutlineIcon />}
                  />
                )}

                {(draft || opened || returned || !update) && (
                  <Button
                    sx={{ ...(GreenButton), marginX: '20px' }}
                    title={update && !draft ? 'Update' : 'Send'}
                    type="submit"
                    disabled={isLoadingStore || isLoadingUpdate}
                    startIcon={<SendIcon />}
                  />
                )}

                {!update && (
                  <Button
                    sx={{ ...(YellowButton) }}
                    title="Save as draft"
                    disabled={isLoadingStore}
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setValue('report_status', 'draft');
                      handleSubmit(onSubmit)();
                    }}
                  />
                )}

                {(update && draft) && (
                  <Button
                    sx={{ ...(YellowButton) }}
                    title="Update draft"
                    startIcon={<SaveIcon />}
                    disabled={isLoadingUpdate}
                    onClick={() => {
                      setValue('update_draft', true);
                      handleSubmit(onSubmit)();
                    }}
                  />
                )}
              </Box>

              <CloseIcon
                sx={CloseIconStyle}
                onClick={() => handleCloseOnSubmit(update)}
              />
            </Box>

            <Divider />

            <Box sx={{
              ...headerBtn,
              display: 'flex',
              justifyContent: 'space-between',
              mb: 0,
              borderRadius: '8px',
              backgroundColor: 'rgb(242 244 246)',
              padding: '10px',
            }}
            >
              <Box sx={TextWithIcon}>
                <Typography variant="subtitle1">Total Amount: </Typography>
                <Typography variant="h5" sx={TextWithIcon}>
                  {totalAmount}
                  <EuroIcon />
                  {totalCompensationsAmount > 0 ? '( +' : '( '}
                  {totalCompensationsAmount}
                  <EuroIcon />
                  )
                </Typography>
              </Box>

              <Box sx={TextWithIcon}>
                <PersonIcon />
                <Typography variant="h5">{`${user?.crew_profile?.crew_shortname} - ${user?.crew_profile?.crew_name}`}</Typography>
              </Box>
            </Box>
            {update && personalReportData?.compensations?.map((compensation, index) => (
              <Box sx={{
                ...headerBtn,
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
                borderRadius: '8px',
                backgroundColor: compensation.type === BONUS ? 'rgb(181, 213, 185)' : 'rgb(230, 197, 196)',
                padding: '10px',
              }}
              >
                <Box sx={TextWithIcon}>
                  <Typography variant="subtitle1">Amount: </Typography>
                  <Typography variant="h5" sx={TextWithIcon}>
                    {compensation.amount}
                    <EuroIcon />
                  </Typography>
                </Box>
                <Box sx={TextWithIcon}>
                  <Typography variant="h5" sx={TextWithIcon}>
                    {COMPENSATION_STATUSES.find(item => item.value === compensation.type)?.label}
                  </Typography>
                </Box>
                <Box sx={TextWithIcon}>
                  <Typography variant="subtitle1">Quantity: </Typography>
                  <Typography variant="h5" sx={TextWithIcon}>
                    1
                  </Typography>
                </Box>
                <Box sx={TextWithIcon}>
                  <Typography variant="subtitle1">Date: </Typography>
                  <Typography variant="h5" sx={TextWithIcon}>
                    {compensation.created_at}
                  </Typography>
                </Box>
                <Box sx={TextWithIcon}>
                  <Typography variant="h5" sx={TextWithIcon}>
                    {compensation.comments}
                  </Typography>
                </Box>
              </Box>
            ))}

            {update && (submitted || declined) ? (
              <Box>
                <Box sx={{
                  mt: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
                >
                  <FormGroup>
                    <Controller
                      name="bank_file"
                      control={control}
                      render={({ field }) => (
                        <Upload
                          {...field}
                          handleUpload={(e) => {
                            field.onChange(e);
                            handleUploadBankFile(e);
                          }}
                          accept="application/pdf"
                          title={bankFileName || 'Upload Bank File (pdf)'}
                        />
                      )}
                    />
                  </FormGroup>
                </Box>
                {personalReportData.decline_purpose ? (
                  <Box>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Box sx={CommentsStyle}>
                          <Typography variant="subtitle2">Decline Purpose:</Typography>
                          <Typography variant="h6">{personalReportData.decline_purpose}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                ) : null}
              </Box>
            ) : null}
            <Box sx={{ mt: 4, position: 'relative' }}>
              {rowFields.map((row, index) => {
                let isDisabled = true;
                if (opened || (returned && row.comments && !row.submitted_at) || !update || draft) {
                  isDisabled = false;
                }

                return (
                  <Card sx={{ ...(RowStyle), ...(hasComment(index) && { backgroundColor: 'rgba(255,0,0,0.16)' }) }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="h5">{`Row #${index + 1}`}</Typography>
                      {(draft || opened || !update) && index !== 0 ? (<StyledDelete onClick={() => rowRemove(index)} />) : null}
                    </Box>
                    <Divider />
                    <Grid container spacing={6} key={row.id}>
                      <Grid item xs={6} md={4}>
                        <FormGroup label="Date" required hasError={!!errors.rows?.[index]?.date}>
                          <Controller
                            name={`rows.${[index]}.date`}
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                sx={{
                                  ...(WhiteInput),
                                  '& > .react-datepicker__tab-loop': {
                                    // position: 'fixed',
                                    zIndex: 2000
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                                disabled={isDisabled}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setDateValue(e, setValue, `rows.${[index]}.date`, 'YYYY-MM-DD');
                                }}
                                value={validateDatePickerValue(field.value)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Transfer Details" required hasError={!!errors.rows?.[index]?.transfer_details}>
                          <Controller
                            control={control}
                            name={`rows.${[index]}.transfer_details`}
                            render={({ field }) => (
                              <Select
                                sx={WhiteInput}
                                options={TRANSFER_DETAILS}
                                placeholder="Transfer Details"
                                disabled={isDisabled}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e.target.value);

                                  if (e.target.value !== 'other') {
                                    handleResetFile(index);
                                  }
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Amount" required hasError={!!errors?.rows?.[index]?.amount}>
                          <Controller
                            name={`rows.${[index]}.amount`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                type="number"
                                sx={{ width: '100%', ...(WhiteInput) }}
                                disabled={isDisabled}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const { value } = e.target;

                                  if (numberFormatValidate(value)) {
                                    field.onChange(value);
                                  }
                                }}
                              />
                            )}
                          />
                          <FieldError error={errors.rows?.[index]?.amount} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Quantity" required hasError={!!errors?.rows?.[index]?.rate}>
                          <Controller
                            name={`rows.${[index]}.rate`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                type="number"
                                sx={{ width: '100%', ...(WhiteInput) }}
                                disabled={isDisabled}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const { value } = e.target;

                                  if (numberFormatValidate(value)) {
                                    field.onChange(value);
                                  }
                                }}
                              />
                            )}
                          />
                          <FieldError error={errors.rows?.[index]?.rate} />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <FormGroup label="Currency" required hasError={!!errors.rows?.[index]?.rate_currency_id}>
                          <Controller
                            control={control}
                            name={`rows.${[index]}.rate_currency_id`}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                sx={WhiteInput}
                                value={field.value && currencies.length ? currencies?.filter(cur => ((cur?.value == field?.value) || (cur?.value == field?.value?.value)))[0] : null}
                                options={currencies}
                                size="small"
                                placeholder="Please select a currency"
                                disabled={isDisabled}
                                onChange={(e, value) => {
                                  field.onChange(value?.value);
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={12} md={8}>
                        <Grid container>
                          {!isDisabled && watch(`rows.${[index]}.transfer_details`) === 'other' ? (
                            <Grid item xs={12}>
                              <Upload
                                accept="application/pdf"
                                title="Document for upload (pdf)"
                                handleUpload={(e) => handleUploadFile(e, index)}
                              />
                              <Divider />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Typography variant="h6">Files</Typography>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              {getValues(`rows.${[index]}.files`)?.map((file, i) => (
                                <Grid item key={i}>
                                  <Chip
                                    color="success"
                                    label={file?.name}
                                    onClick={handleDownloadFile(file?.id)}
                                    onDelete={() => isDisabled ? undefined : handleResetFile(index, file.name)}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Divider />
                        <Box sx={TextWithIcon}>
                          <Typography variant="subtitle1">Amount: </Typography>
                          <Typography variant="h5">
                            {formatNumber((watch(`rows.${[index]}.rate`) || 0) * (watch(`rows.${[index]}.amount`) || 0))}
                          </Typography>
                        </Box>
                        <Box sx={TextWithIcon}>
                          <Typography variant="subtitle1">Currency: </Typography>
                          <Typography variant="h5">
                            {currencies.length ? (currencies?.find(cur => ((cur?.value == watch(`rows.${[index]}.rate_currency_id`))))?.label || 'N/A') : 'N/A'}
                          </Typography>

                        </Box>
                      </Grid>
                    </Grid>

                    <Divider />

                    {hasComment(index) && (
                      <>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Box sx={CommentsStyle}>
                              <Typography variant="subtitle2">Comment:</Typography>
                              <Typography variant="h6">{getValues(`rows.${index}.comments`)}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Divider />
                      </>
                    )}

                  </Card>
                );
              })}
            </Box>

            {draft || opened || !update ? (
              <Box>
                <Button
                  title="Add Row"
                  startIcon={<Plus />}
                  sx={{ ...btnS, ...GreenButton, maxWidth: '140px' }}
                  size="small"
                  onClick={() => rowAppend(defaultValues.rows[0])}
                />
              </Box>
            ) : null}
          </Card>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PersonalReportForm;
